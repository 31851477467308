import React from 'react';
import { graphql, PageProps } from 'gatsby';

import Layout from '../layouts/Layout';
import SEO from '../components/SEO';
import WallWrapper from '../components/wall/WallWrapper';
import { Collections } from '../@types/GraphQL';

interface BackstageProps extends PageProps {
  data: {
    liveRecording?: {
      edges: {
        node: {
          childMdx: {
            id: string;
            frontmatter: Collections.LiveRecording;
            slug: string;
          };
        };
      }[];
    };
    liveVideo?: {
      edges: {
        node: {
          childMdx: {
            id: string;
            frontmatter: Collections.LiveVideo;
            slug: string;
          };
        };
      }[];
    };
    photo?: {
      edges: {
        node: {
          childMdx: {
            id: string;
            frontmatter: Collections.Photo;
            slug: string;
          };
        };
      }[];
    };
    polaroid?: {
      edges: {
        node: {
          childMdx: {
            id: string;
            frontmatter: Collections.Polaroid;
            slug: string;
          };
        };
      }[];
    };
    poster?: {
      edges: {
        node: {
          childMdx: {
            id: string;
            frontmatter: Collections.Poster;
            slug: string;
          };
        };
      }[];
    };
    text?: {
      edges: {
        node: {
          childMdx: {
            id: string;
            frontmatter: Collections.Text;
            slug: string;
          };
        };
      }[];
    };
    show?: {
      edges: {
        node: {
          childMdx: {
            id: string;
            frontmatter: Collections.Show;
            slug: string;
          };
        };
      }[];
    };
  };
}

const BackstagePage: React.FC<BackstageProps> = function (props) {
  const { data } = props;

  return (
    <Layout currPage={props.location.pathname}>
      <SEO title="Backstage | Courtney Barnett" />
      <WallWrapper data={data} />
    </Layout>
  );
};

export default BackstagePage;

export const pageQuery = graphql`
  query backstageQuery {
    liveRecording: allFile(
      filter: {
        sourceInstanceName: { eq: "live_recording" }
        childMdx: { frontmatter: { backstage: { eq: true } } }
      }
    ) {
      ...LiveRecording
    }
    liveVideo: allFile(
      filter: {
        sourceInstanceName: { eq: "live_video" }
        childMdx: { frontmatter: { backstage: { eq: true } } }
      }
    ) {
      ...LiveVideo
    }
    photo: allFile(
      filter: {
        sourceInstanceName: { eq: "photo" }
        childMdx: { frontmatter: { backstage: { eq: true } } }
      }
    ) {
      ...Photo
    }
    polaroid: allFile(
      filter: {
        sourceInstanceName: { eq: "polaroid" }
        childMdx: { frontmatter: { backstage: { eq: true } } }
      }
    ) {
      ...Polaroid
    }
    poster: allFile(
      filter: {
        sourceInstanceName: { eq: "poster" }
        childMdx: { frontmatter: { backstage: { eq: true } } }
      }
    ) {
      ...Poster
    }
    text: allFile(
      filter: {
        sourceInstanceName: { eq: "text" }
        childMdx: { frontmatter: { backstage: { eq: true } } }
      }
    ) {
      ...Text
    }
    show: allFile(filter: { sourceInstanceName: { eq: "show" } }) {
      ...Show
    }
  }
`;
